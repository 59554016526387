/** @type {HTMLCanvasElement} */
import { colors, veriables } from "./globalVeriable";
const c = document.getElementById("canvas1");
c.height = window.innerHeight;
c.width = window.innerWidth / 2 + 200;
const cBound = c.getBoundingClientRect();
const ctx = c.getContext("2d");

console.log(cBound.height);

//to radient function
function toRadient(degrees) {
  return degrees * (Math.PI / 180);
}
var mouse = {
  x: 0,
  y: window.innerHeight / 2,
};
window.addEventListener("mousemove", (e) => {
  mouse.x = e.x;
  mouse.y = e.y;
});
//color gradient
var grd = ctx.createLinearGradient(0, 0, 200, 0);
grd.addColorStop(0, "#ffffff");
grd.addColorStop(1, "#ffffff");

var offset = 0;
var balls = [];
class Ball {
  constructor() {
    // this.x = 100 + offset;
    // this.y = mouse.y;
    this.radius = Math.floor(Math.random() * 20) + 5;
    this.currentRadius = 0;
    this.expired = false;
    this.duration = Math.floor(Math.random() * (2000 - 500 + 1)) + 500;
    this.x = Math.floor(Math.random() * c.width) + 200;
    this.y = Math.floor(Math.random() * c.height);
    this.lineWidth = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
    // this.velocity = 0;
    // this.flag = Math.floor(Math.random() * 2) + 1;
    // this.angle = 0;
    this.startTime = null;
    var minX = this.x - 100;
    var maxX = this.x + 100;
    var minY = this.y - 100;
    var maxY = this.y + 100;
    this.destination = {
      x: Math.floor(Math.random() * (maxX - minX + 1)) + minX,
      y: Math.floor(Math.random() * (maxY - minY + 1)) + minY,
    };

    this.stage = 1;

    this.line = {
      x: this.x,
      y: this.y,
    };
  }
  updatePosition() {
    // this.angle == 365 ? (this.angle = 0) : this.angle++;
    // this.x++;
    // this.y = this.y + Math.sin(toRadient(this.angle * 2)) * 0.5;
    // if (this.flag == 1) this.y += 0.5;
    // if (this.flag == 2) this.y -= 0.5;
  }
  update() {
    //make redius big
    ctx.beginPath();
    ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
    ctx.lineWidth = this.lineWidth;
    if (this.stage == 1) {
      this.currentRadius < this.radius
        ? (this.currentRadius += 0.2)
        : (this.stage = 2);
      // ctx.arc(this.x, this.y, this.currentRadius, 0, 2 * Math.PI);
      ctx.arc(this.x, this.y, this.currentRadius, 0, 2 * Math.PI);
      ctx.fillStyle = "rgba(0, 0, 0, 0.1)";

      ctx.fill();
    }
    //make radius small
    if (this.stage == 2) {
      this.currentRadius > 2 ? (this.currentRadius -= 0.2) : (this.stage = 3);
      // ctx.arc(this.x, this.y, this.currentRadius, 0, 2 * Math.PI);
      ctx.arc(this.x, this.y, this.currentRadius, 0, 2 * Math.PI);
      ctx.stroke();
    }
    //move line to destination
    if (this.stage == 3) {
      if (!this.startTime) this.startTime = performance.now();
      var deltaTime = (performance.now() - this.startTime) / this.duration;
      this.line.x =
        this.line.x + (this.destination.x - this.line.x) * deltaTime;

      this.line.y =
        this.line.y + (this.destination.y - this.line.y) * deltaTime;

      if (deltaTime >= 1) {
        this.line.x = this.destination.x;
        this.line.y = this.destination.y;
        this.stage = 4;
        this.startTime = null;
      }
      ctx.moveTo(this.x, this.y);
      ctx.lineTo(this.line.x, this.line.y);
      ctx.stroke();
    }
    if (this.stage == 4) {
      if (!this.startTime) this.startTime = performance.now();

      var deltaTime = (performance.now() - this.startTime) / this.duration;

      this.x = this.x + (this.destination.x - this.x) * deltaTime;

      this.y = this.y + (this.destination.y - this.y) * deltaTime;

      if (deltaTime >= 1) {
        this.x = this.destination.x;
        this.y = this.destination.y;
        this.startTime = null;
        this.stage = 5;
      }
      ctx.moveTo(this.x, this.y);
      ctx.lineTo(this.line.x, this.line.y);
      ctx.stroke();
    }
    if (this.stage == 5) {
      this.currentRadius < 20
        ? (this.currentRadius += 0.5)
        : (this.expired = true);
      ctx.arc(this.x, this.y, this.currentRadius, 0, 2 * Math.PI);
      ctx.stroke();
    }

    //update veriables
    // this.currentRadius < this.radius
    //   ? (this.currentRadius += 0.2)
    //   : this.updatePosition();
    // ctx.beginPath();
  }
}
var spawnCooldown = 0;
function spawnBalls() {
  if (spawnCooldown != 0) {
    spawnCooldown--;
    return;
  }
  if (balls.length < 20) {
    var newBall = new Ball();
    balls.push(newBall);
    spawnCooldown = 50;
  }
}
function updateBalls() {
  for (let i = 0; i < balls.length; i++) {
    const ball = balls[i];
    if (ball.expired) {
      balls.splice(i, 1);
    } else {
      ball.update();
    }
  }
}

function animatePullEffect() {
  requestAnimationFrame(animatePullEffect);
  //clear canvas before drawing new
  ctx.clearRect(0, 0, c.width, c.height);

  //start curve
  ctx.beginPath();
  ctx.moveTo(c.width, 0);
  ctx.lineTo(100, 0);
  if (
    Math.abs(mouse.x - cBound.left - 100) < 100 &&
    veriables.loadingPage == false
  ) {
    makeStickyEffect(true);
  } else {
    makeStickyEffect(false);
  }

  //complete line
  ctx.lineTo(100, c.height);
  ctx.lineTo(c.width, c.height);
  ctx.fillStyle = colors.currentBack;
  ctx.fill();

  //spawn balls if necessary
  // spawnBalls();
  // updateBalls();
}
animatePullEffect();

function makeStickyEffect(follow) {
  var x, angle;
  if (follow == true) {
    const realoffset = Math.trunc(mouse.x - cBound.left - 100);
    realoffset < offset
      ? (offset -= 1)
      : realoffset > offset
      ? (offset += 1)
      : (offset = realoffset);
  } else if (offset != 0) {
    offset = Math.trunc(offset);
    if (offset < 0) offset++;
    else if (offset > 0) offset--;
    else offset = 0;
  }

  //make curve towards mouse pos
  x = 180 / mouse.y;
  angle = 0;
  for (let i = 0; i < mouse.y; i++) {
    angle = angle + x;
    ctx.lineTo(100 + Math.sin(toRadient(angle * 0.5)) * offset, i);
  }
  // console.log(angle);
  //complete curve after mouse position
  x = 180 / Math.abs(c.height - mouse.y);
  for (let i = mouse.y; i < c.height; i++) {
    angle = angle + x;
    ctx.lineTo(100 + Math.sin(toRadient(angle * 0.5)) * offset, i);
  }
}
